about<template>
  <div>
    <Navigation />
    <img :src="imgUrl" alt="" class="banner">
    <div class="xuanchuan_box">
      <img src="../assets/about/xuanchuanTitle.png" alt="" class="title1">
      <div class="xuanchuan_ct_box flex">
        <div class="squire_bg"></div>
        <div class="xuanchuan1_box">
          <img src="../assets/about/xuanchuan1.png" alt="" class="xuanchuan1_img">
          <!-- <img src="../assets/about/xuanchuanHover1.png" alt="" class="xuanchuan1_img_hover"> -->
          <div class="xuanchuan1_img_hover">
            <div class="xuanchuan1_img_title_box">
              <h4 class="xuanchuan1_title">企宣通简介</h4>
            </div>
            <p class="xuanchuan1_ct">{{ introduction }}</p>
          </div>
        </div>
        <div class="xuanchuan_rt_box flex">
          <div class="xuanchuan2_img_box">
            <img src="../assets/about/xuanchuan2.png" alt="" class="xuanchuan2_img">
            <div class="xuanchuan2_img_hover">
              <div class="xuanchuan1_img_title_box">
                <h4 class="xuanchuan1_title">我们的口号</h4>
              </div>
              <p class="xuanchuan1_ct">{{ slogan }}</p>
            </div>
          </div>
          <div class="xuanchuan2_img_box">
            <img src="../assets/about/xuanchuan3.png" alt="" class="xuanchuan2_img">
            <div class="xuanchuan2_img_hover">
              <div class="xuanchuan1_img_title_box">
                <h4 class="xuanchuan1_title">我们能做的</h4>
              </div>
              <p class="xuanchuan1_ct">{{ cando }}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <img src="../assets/about/title.png" alt="" class="title1">
    <div class="brief_img_box">
      <div class="xuanchuan1_img_title_box">
        <h4 class="xuanchuan1_title">杭州宇耀网络技术有限公司</h4>
      </div>
      <p class="xuanchuan1_ct">{{ company_introduction }}</p>
    </div>
    <!-- <img src="../assets/about/brief.png" alt="" class="brief_img"> -->

    <div class="ct_box">
      <ul class="flex list_ul">
        <li :class="show == 0 ? 'active' : ''" @click="show = 0">
          <img src="../assets/about/fagao1.png" alt="">
          <img src="../assets/about/fagao2.png" alt="">
          <span></span>
        </li>
        <li :class="show == 1 ? 'active' : ''" @click="show = 1">
          <img src="../assets/about/jiesuan1.png" alt="">
          <img src="../assets/about/jiesuan2.png" alt="">
          <span></span>
        </li>
        <li :class="show == 2 ? 'active' : ''" @click="show = 2">
          <img src="../assets/about/lianxi1.png" alt="">
          <img src="../assets/about/lianxi2.png" alt="">
        </li>
      </ul>
    </div>

    <div class="ct_title_1" v-show="show == 0">
      <p v-html="publish_explain"></p>
    </div>

    <div class="ct_title_2" v-show="show == 1">
      <p v-html="cost_settlement"></p>
    </div>

    <div class="ct_title_3" v-show="show == 2">
      <p>售前QQ：{{ presale_qq }}</p>
      <p>售后QQ：{{ aftersale_qq }}</p>
      <p>咨询电话：{{ before_tel }}</p>
      <p>售后电话：{{ after_tel }}</p>
      <p>投诉电话：{{ complaint_tel }}</p>
      <p>合作电话：{{ cooperate_tel }}</p>
      <p>地址：{{ address }}</p>
      <p>服务邮箱：{{ email }}</p>


    </div>
    <ul class="safeguard_box flex">
      <li v-for="(item, index) in safeguardList" :key="index">
        <img :src="item.imgUrl" alt="">
        {{ item.title }}
      </li>
    </ul>
    <footerBot />

  </div>
</template>

<script>
import { toRaw } from 'vue'

export default {
  name: '',
  created() { },
  mounted() {
    this.BannerImgFn()
    this.bgImgGet()
  },
  data() {
    return {
      show: 0,
      safeguardList: [
        {
          imgUrl: require('../assets/about/you.png'),
          title: '优秀媒体全面覆盖',
        },
        {
          imgUrl: require('../assets/about/shou.png'),
          title: '7 x 24小时售后服务',
        },
        {
          imgUrl: require('../assets/about/cheng.png'),
          title: '服务诚信公开透明',
        },
        {
          imgUrl: require('../assets/about/bao.png'),
          title: '有效期内被删补发',
        },
      ],
      introduction: '', //简介
      slogan: '',//口号
      cando: '', //我们能做的
      company_introduction: '', //公司简介
      publish_explain: '', //发稿说明
      cost_settlement: '', //费用结算
      presale_qq: '', //售前qq  
      aftersale_qq: '', //售后qq
      before_tel: '', //咨询电话
      after_tel: '',// 售后电话
      complaint_tel: '', //投诉电话
      cooperate_tel: '', //合作电话
      address: '', //地址
      email: '',//服务邮箱
      imgUrl: ''

    }
  },
  methods: {
    BannerImgFn() {
      this.curlGet('/api/advert/detail', {
        mark: 'about_banner_01'
      }).then(res => {
        if (res.data.code) {
          this.imgUrl = res.data.data.image
        }
      })
    },
    bgImgGet() {
      this.curlGet('/api/index/website').then(res => {
        console.log(res);
        if (res.data.code) {
          this.introduction = res.data.data.introduction
          this.slogan = res.data.data.slogan
          this.cando = res.data.data.cando
          this.company_introduction = res.data.data.company_introduction
          this.publish_explain = res.data.data.publish_explain
          this.cost_settlement = res.data.data.cost_settlement
          this.presale_qq = res.data.data.presale_qq //售前qq  
          this.aftersale_qq = res.data.data.aftersale_qq //售后qq
          this.before_tel = res.data.data.before_tel //咨询电话
          this.after_tel = res.data.data.after_tel// 售后电话
          this.complaint_tel = res.data.data.complaint_tel//投诉电话 
          this.cooperate_tel = res.data.data.cooperate_tel //合作电话
          this.address = res.data.data.address //地址
          this.email = res.data.data.email//服务邮箱

        }
      })
    }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/About';

.list_ul li :nth-child(1):hover {
  .ct_title_1 {
    display: block;
  }
}
</style>